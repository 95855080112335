import React, { useEffect, useLayoutEffect } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { setExperiment } from "app-store-v2/app/actions"
import { history } from "app-store-v2/index"
import useCollection from "areas/collection/hoc/use-collection"
import IntersectionWrapper from "components/common/intersection-wrapper"
import { jewlr_t, isTestMode } from "helpers/application"
import { logCurrentPageInCollectionStats } from "helpers/collection"
import scrollToElement from "helpers/scroll-to-element"
import useScrollRestoration from "helpers/use-scroll-restoration"
import withLanding from "hoc/landing/with-landing"

import LandingElement from "./landing-element"
import LandingLoading from "./loaders"

const Landing = () => {
  const { hash, pathname } = useLocation()
  const { data, isRefetching } = useCollection()
  const dispatch = useDispatch()
  const mdLandingExp = useSelector((state) => state.experiment.mdLanding)
  const isMDLanding = pathname.includes(
    `/mothers-day-${jewlr_t("terms.jewelry")}-sale`
  )

  useScrollRestoration()

  useEffect(() => {
    const setMdLandingExp = () => {
      if (mdLandingExp && isMDLanding) {
        dispatch(setExperiment({ mdLanding: mdLandingExp }))
      }

      window.removeEventListener("click", setMdLandingExp)
      window.removeEventListener("scroll", setMdLandingExp)
      window.removeEventListener("touchstart", setMdLandingExp)
    }

    window.addEventListener("click", setMdLandingExp)
    window.addEventListener("scroll", setMdLandingExp)
    window.addEventListener("touchstart", setMdLandingExp)

    return () => {
      window.removeEventListener("click", setMdLandingExp)
      window.removeEventListener("scroll", setMdLandingExp)
      window.removeEventListener("touchstart", setMdLandingExp)
    }
  }, [mdLandingExp, dispatch, isMDLanding])

  useEffect(() => {
    if (data) {
      logCurrentPageInCollectionStats(data.url_path)

      if (data.unique_event_id) {
        window.cache_free_ssr_data.unique_event_ids.landing =
          data.unique_event_id
      }

      if (!isTestMode) {
        dataLayer.push({
          event: "view-landing",
          landingPath: data.url_path,
          landingTitle: data.title,
          viewLandingUniqueEventId:
            window.cache_free_ssr_data.unique_event_ids.landing,
        })
      }
    }
  }, []) // Runs only once

  useLayoutEffect(() => {
    if (data && data.elements && hash) {
      const element = data.elements.find((el) => `#${el.anchor_name}` === hash)

      if (element) {
        scrollToElement(hash)
        history.replace({ hash: null })
      }
    }
  }, [data, hash])

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      {data.elements_list
        .filter(
          (el) =>
            !isMDLanding ||
            (mdLandingExp.variant_id === 1 &&
              !el.anchor_name?.endsWith("-0")) ||
            (mdLandingExp.variant_id === 0 && !el.anchor_name?.endsWith("-1"))
        )
        .map((el, index) => {
          const elId = el.anchor_name || el.id
          const landing_element = data.elements.find((elm) => elm.id === el.id)
          let content

          if (landing_element) {
            // Only render the first THREE elements
            if (index <= 2) {
              content = (
                <LandingElement
                  el={landing_element}
                  index={index}
                  wrapTheChild={false}
                />
              )
            } else {
              // The other elements will be load/render when being in the view port
              content = (
                <IntersectionWrapper>
                  <LandingElement
                    el={landing_element}
                    index={index}
                    wrapTheChild={false}
                  />
                </IntersectionWrapper>
              )
            }
          }

          return (
            <div id={elId} key={`landing-wrapper-${index}`}>
              {content}
            </div>
          )
        })}
      {isRefetching && <LandingLoading partial />}
    </>
  )
}

export default withLanding(Landing)
